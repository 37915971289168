@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: #2459ad;
    --primary-light: #2b6bd1;
    --secondary: #edf4ff;
    --secondary-light: #cce3ff;
    --tertiary: #ececec;
    --tertiary-light: #e0e0e0;
    --accent-sale: #ec6e00;
    --accent-rent: #03adf7;
    --text: #22313e;
    --text2: #80888f;
    --alert: #d01426;
    --alert2: #ef4444;
    --background: #f4f4f4;
    --paper: #ffffff;
    --stroke: #dedede;
    --placeholder: #b4b8bc;
    --disabled: #f9f9f9;
    --note: #fdf9e6;
    --badge: #dc2626;
    --success: #40d124;
    --success-light: #e6fee1;
    --information: #2563eb;
    --information-light: #e3f3ff;
    --error: #ff0000;
    --error-light: #ffe6e6;
    --warning: #ffc119;
    --warning-light: #fff6dd;
    --pending-processing: #7239ea;
    --pending-processing-light: #f8f5ff;
    --pending-approval: #2459ad;
    --pending-approval-light: #e4eaf3;
    --approved: #22c55e;
    --approved-light: #e9f9ef;
    --rejected: #d01426;
    --rejected-light: #fae7e9;
    --green: #4aa366;
    --orange: #ef8e34;
    --service-primary: #1f61eb;
    --service-secondary: #94b4f5;
    --service-text: #343a40;
    --service-text2: #707070;
    --e-commerce-primary: #df4949;
    --e-commerce-rating: #ffbd0c;
    --e-commerce-negotiable: #daa310;
    --e-commerce-negotiable-light: #fff4ed;
    --1: 0 1px 1px #22222240;
    --2: 0 2px 2px #22222233;
    --3: 0 -1px 15px #a2a2a240;
    --4: 0 3px 8px #8484863d;
    --5: 0 0 10px #34343414;
    --6: 0 1px 30px #3899f333;
    --7: 0 1px 2px #00000033;
    --8: 0 0 4px 0 #cccccc;
    --9: 0 1px 3px #53535333;
    --10: 0 3px 10px #34343414;
    --11: 0 0 5px #79adff;
    --12: 0 4px 6px #2c2c2c0a;
    --13: -1px 0 5px 0 #0000001f;
    --15: 0 4px 20px #4a4a4e29;
    --16: 0 4px 12px #2459ad80;
    --17: 0 1px 10px #877f7f40;
    --18: 0 1px 8px #3899f333;
    --19: 0 1px 1px 0 #2222223d;
    --20: 0 1px 8px #1d1d1d40;
    --21: 0 1px 1px #2459ad40;
    --22: 0 2px 4px #00000026;
    --23: 0 2px 5px -1px #32325d33, 0 1px 3px -1px #0000000d;
    --24: 0 4px 16px #2c2c2c14;
    --25: 0 10px 25px #0798ff17;
    --26: 0 0 20px #0000000d;
  }
  body {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: var(--text);
    background-color: var(--background);
    min-width: 280px;
  }
  button,
  a,
  input,
  textarea {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    cursor: default;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
    -o-appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    -ms-progress-appearance: textfield;
  }
}

#nprogress .bar {
  @apply !bg-primary;
}
.tooltip {
  @apply invisible absolute;
}
.has-tooltip:hover .tooltip {
  @apply visible;
}
.scrollbar::-webkit-scrollbar {
  @apply h-[12px] w-[12px];
}
.scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-[6px] border-solid border-[#e4e6ec] bg-[#000000]/[.3];
}
.scrollbar::-webkit-scrollbar-track {
  @apply bg-[#e4e6ec];
}
.scrollbar::-webkit-scrollbar-track:horizontal {
  @apply rounded-b-[6px];
}
.scrollbar::-webkit-scrollbar-track:vertical {
  @apply rounded-r-[6px];
}
.scrollbar::-webkit-scrollbar-corner {
  @apply rounded-br-[6px];
}
