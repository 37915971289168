.DayPicker {
  @apply shadow-none !w-full min-w-[224px] !mt-[-38px];
}
.MonthPicker .DayPicker {
  @apply min-w-[256px];
}
.DayPicker > div > div {
  @apply !w-full;
}
.CalendarMonth {
  @apply !p-0 w-full;
}
.CalendarMonth_table {
  @apply ml-[-4px];
}
.DesktopDayPicker .MonthPicker .DayPicker_transitionContainer {
  @apply !h-[216px];
}
.MobileDayPicker .MonthPicker .DayPicker_transitionContainer {
  @apply !h-[292px];
}
.MonthPicker .DayPicker_weekHeader,
.MonthPicker .CalendarMonth_table {
  @apply !h-0 !w-0 hidden;
}
.MonthPicker .DayPickerNavigation svg {
  @apply !h-0 !w-0 text-transparent;
}
.DayPicker_transitionContainer {
  @apply !w-full;
}
.CalendarMonthGrid_month__horizontal,
.CalendarMonth_table {
  @apply !w-full;
}
.CalendarMonthGrid {
  @apply !w-full pr-[16px];
}
.DayPicker_weekHeader {
  @apply ml-0 w-full !px-0 top-[86px];
}
.DayPicker_weekHeader_ul {
  @apply w-full flex justify-center;
}
.DesktopDayPicker .DayPicker_weekHeader_ul {
  @apply ml-[-3%];
}
.MobileDayPicker .DayPicker_weekHeader_ul {
  @apply ml-[-3%] screen-480:ml-[-2%] screen-640:ml-[-1%] screen-1140:ml-[-0.5%];
}
.DayPicker_weekHeader_li {
  @apply !w-full font-[600] text-text2 flex justify-center items-center;
}
.DesktopDayPicker .DayPicker_weekHeader_li {
  @apply text-[12px] leading-[15px] h-[24px];
}
.MobileDayPicker .DayPicker_weekHeader_li {
  @apply text-[16px] leading-[20px] h-[28px];
}
.DayPickerNavigation {
  @apply absolute top-[36px] flex justify-between w-full absolute px-[2%];
}
.CalendarMonth_caption {
  @apply pt-[38px] pb-[64px];
}
.DesktopDayPicker .CalendarMonth_caption {
  @apply text-[14px] leading-[18px];
}
.CalendarDay {
  @apply border-none bg-transparent hover:bg-transparent focus:bg-transparent hover:border-none;
  -webkit-tap-highlight-color: transparent;
}
.CalendarDay > .CalendarDay__div {
  @apply text-text items-center justify-center inline-flex w-full;
}
.DesktopDayPicker .CalendarDay > .CalendarDay__div {
  @apply h-[24px];
}
.MobileDayPicker .CalendarDay > .CalendarDay__div {
  @apply h-[28px];
}
.CalendarDay > .CalendarDay__div > .CalendarDay__div {
  @apply items-center justify-center inline-flex rounded-full;
}
.DesktopDayPicker .CalendarDay > .CalendarDay__div > .CalendarDay__div {
  @apply p-[4px] text-[12px] leading-[15px] w-[24px] h-[24px];
}
.MobileDayPicker .CalendarDay > .CalendarDay__div > .CalendarDay__div {
  @apply p-[6px] text-[14px] leading-[18px] w-[28px] h-[28px];
}
.CalendarDay.CalendarDay__outside > .CalendarDay__div > .CalendarDay__div {
  @apply opacity-20;
}
.CalendarDay.CalendarDay__today > .CalendarDay__div > .CalendarDay__div {
  @apply bg-alert text-paper font-[600] opacity-100;
}
.CalendarDay.CalendarDay__selected > .CalendarDay__div > .CalendarDay__div {
  @apply bg-primary text-paper font-[600] opacity-100;
}
.CalendarDay__blocked_out_of_range {
  @apply opacity-50;
}