.chat {
  scrollbar-width: 14px;
  scrollbar-color: #000000 #ffffff;
}
.chat::-webkit-scrollbar {
  width: 14px;
  border-left: 1px solid var(--stroke);
}
.chat::-webkit-scrollbar-track {
  background: transparent;
}
.chat::-webkit-scrollbar-thumb {
  background-color: var(--stroke);
  border-radius: 20px;
  background-clip: content-box;
  border: 3px solid transparent;
}
.chat::-webkit-scrollbar-thumb:hover {
  background: var(--placeholder);
  background-clip: content-box;
  border: 3px solid transparent;
}
