.leaflet-control-attribution {
  display: none !important;
}
.leaflet-div-icon {
  background-color: transparent !important;
  margin-left: -16px !important;
  margin-top: -36px !important;
  width: 28px !important;
  height: 36px !important;
  border: unset !important;
  background-image: url('/icons/custom-marker.svg');
}
.mf-ui.mf-ui-bottom-right {
  bottom: 55px !important;
}
#map-4d-mobile .mf-ui.mf-ui-bottom-right {
  position: fixed;
}
.leaflet-interactive {
  position: relative;
}
.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  position: absolute;
  top: -30px;
}
