.range-slider {
  @apply bg-stroke;
}
.range-slider__range {
  @apply !bg-primary;
}
.range-slider__thumb[data-lower] {
  @apply bg-paper border border-stroke shadow-22;
}
.range-slider__thumb[data-upper] {
  @apply bg-paper border border-stroke shadow-22;
}
