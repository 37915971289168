.toc {
  overflow-y: auto;
}

.toc > .toc-list {
  overflow: hidden;
  position: relative;
}

.node-name--H1 {
  line-height: 24px;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  margin: 12px 0;
  font-weight: bold;
}

.node-name--H2 {
  font-family: 'Mulish', sans-serif;
  font-size: 13px;
  margin: 12px 0;
}
.toc-list-item {
  line-height: 20px;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  margin: 12px 0;
}
.toc > .toc-list li {
  list-style: none;
}
.toc-list {
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
}
a.toc-link {
  color: currentColor;
  height: 100%;
}
.node-name--H1 {
  font-size: 16px;
}
.node-name--H2 {
  font-size: 14px;
}
.is-collapsible {
  max-height: 1000px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}
.is-collapsed {
  max-height: 0;
}
.is-position-fixed {
  position: fixed !important;
  top: 0;
}
.is-active-link {
  color: #2459ad !important;
  font-weight: 700;
}
